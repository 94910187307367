.slide{
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slide .content{
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 20px;
    max-width: 500px;
    font-weight: 500;
    margin: 10px 0px;
    color: white;
}

.slide button{
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    text-transform: uppercase;
    background-color: var(--primary);
    cursor: pointer;
}

.slide1{
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://i.ndtvimg.com/i/2017-03/masala_620x350_41490941144.jpg");
}

.slide2{
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://cdn.masalamonk.com/2019/08/Artisnal-Masala-Gift-Box-Raita-Masala-Garam-Masala-Bharwa-Masala.jpeg");
}
.slide3{
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://media.istockphoto.com/photos/indian-masala-chai-tea-picture-id614533094?k=20&m=614533094&s=612x612&w=0&h=YvGYiRv3aa5YL0XRBx3W2A30RHCAS7yx1uuJR2xIROk=");
}

.featureImage{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 120px;
}

.OurStoryBtn{
    border-radius: 20px;
    border: 2px solid var(--primary);
    background-color: #f8f4ec;
    padding: 10px 20px;
    text-transform: uppercase;
    color: var(--primary);
    font-weight: 600;
}

.subscriptionInput{
    border: none;    
    color: var(--primary);
    flex-grow: 1;
    margin-left: 3px;
}

.subscriptionInput:focus{
    outline: none;
}

.subscriptionBtn{
    height: 100%;
    padding: 14px 20px;
    background-color: var(--primary);
    border-radius: 25px;
    color: white;
    font-size: 18px;
    outline: none;
    border: none;
    font-weight: 500;
    margin: 2px ;
}