@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);
body{
    margin: 0;
}

:root {
    --primary:#945030; 
}

*{
    font-family: 'Montserrat', sans-serif;
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgb(209, 209, 209);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #945030;
    background: var(--primary)
}

/* Navbar */
.nav{
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px black;
}

.menuBtn{
    display: none;
}

@media screen and (max-width: 930px) {
    .navMenu .navMenuLinks .mobileMenu{
        height: 100%;
        width: 75%;
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        background-color: white;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 30px;
    }
    .navMenu .navMenuLinks .mobileMenu a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 15px;
        display: block;
        transition: 0.3s;
    }
    .menuBtn{
        display: block;
    }

    .desktopNav{
        display: none;
    }
}

.nav .logo{
    width: 150px;
}

.nav a{
    text-decoration: none;
    padding: 10px;
    margin: 10px;
    color: #945030;
    color: var(--primary);
    font-weight: 500;
}

.nav .icons{
    display: flex;
    justify-content: space-between;   
}

.nav .icons svg{
    color: #945030;
    color: var(--primary);
    margin: 0px 7px;
    stroke-width: 1.3px;
}

/* Footer */
.footerLink{
    text-decoration: none;
    color: white;
    margin: 10px 0px;
    font-size: 13px;
}

.copyright{
    color: white;
    font-size: 13px;
}

/* Products */
.productImage{
    width: 300px;
    height: 300px;
}

.productLink{
    text-decoration: none;
    color: black;
    transition: 0.3s;
    font-size:13px; 
    font-weight: 500;
    max-width: 300px;
}

.productLink:hover{
    color: #945030;
    color: var(--primary)
}



/* Cart Sidebar */
.cartSidebar{
    position: fixed;
    min-height: 100vh;
    width: 100%;
    z-index: 9999;
    background-color: rgb(0, 0, 0, 0.7);
}

.cartSidebar .sidebarBody{
    width: 450px;
    background-color: white;
    min-height: 100vh;
    right: 0;
    position: fixed;   
}

.sidebarQuantity{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
}

.sidebarQuantity{
    font-weight: 500;
}

.sidebarQuantity .quantityBtns{
    border: 1px solid gray;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}

.sidebarQuantity input{
    width: 40px;
    text-align: center;
    border: none;
}

.sidebarQuantity button{
    border: none;
    font-size: 20px;
    background-color: transparent;
}


@media screen and (max-width: 450px) {
    .cartSidebar .sidebarBody{
        width: 100%;
    }
}

.checkOutBtn{
    padding: 12px 20px;
    border: none;
    font-weight: 600;
    color: white;
    font-size: 15px;
    width: 100%;
    text-transform: uppercase;
    background-color: #945030;
    background-color: var(--primary);
    cursor: pointer;
    margin: 10px 0px;
    transition: 0.3s;
    border: 1px solid #945030;
    border: 1px solid var(--primary);
}

.checkOutBtn:hover{
    background-color: white;
    color: #945030;
    color: var(--primary);
}
.slide{
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slide .content{
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 20px;
    max-width: 500px;
    font-weight: 500;
    margin: 10px 0px;
    color: white;
}

.slide button{
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    text-transform: uppercase;
    background-color: var(--primary);
    cursor: pointer;
}

.slide1{
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://i.ndtvimg.com/i/2017-03/masala_620x350_41490941144.jpg");
}

.slide2{
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://cdn.masalamonk.com/2019/08/Artisnal-Masala-Gift-Box-Raita-Masala-Garam-Masala-Bharwa-Masala.jpeg");
}
.slide3{
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://media.istockphoto.com/photos/indian-masala-chai-tea-picture-id614533094?k=20&m=614533094&s=612x612&w=0&h=YvGYiRv3aa5YL0XRBx3W2A30RHCAS7yx1uuJR2xIROk=");
}

.featureImage{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 120px;
}

.OurStoryBtn{
    border-radius: 20px;
    border: 2px solid var(--primary);
    background-color: #f8f4ec;
    padding: 10px 20px;
    text-transform: uppercase;
    color: var(--primary);
    font-weight: 600;
}

.subscriptionInput{
    border: none;    
    color: var(--primary);
    flex-grow: 1;
    margin-left: 3px;
}

.subscriptionInput:focus{
    outline: none;
}

.subscriptionBtn{
    height: 100%;
    padding: 14px 20px;
    background-color: var(--primary);
    border-radius: 25px;
    color: white;
    font-size: 18px;
    outline: none;
    border: none;
    font-weight: 500;
    margin: 2px ;
}
.originalPrice{
    text-decoration: line-through;
    color: gray;
    margin-right: 10px;
    font-weight: 500;
}

.discountPrice{
    color: var(--primary);
    font-weight: 500;
}

.quantity {
    display: flex;
    justify-content: space-evenly;
    width: 120px;
    align-items: stretch;
    border: 1px solid ;
    border-radius: 25px;
    height: 40px;
    margin-right: 10px;
}

.quantity button{
    background-color: transparent;
    border: none;
    font-size: 27px;
    font-weight: 600;
}

.quantity input{
    width: 40px;
    border: none;
    outline: none;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
}

.quantity input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

.addCartBtn{
    padding: 12px 20px;
    border: none;
    border-radius: 25px;
    font-weight: 600;
    color: white;
    font-size: 15px;
    text-transform: uppercase;
    background-color: var(--primary);
    cursor: pointer;
    transition: 0.3s;
    border: 1px solid var(--primary);
}

.addCartBtn:hover{
    background-color: white;
    color: var(--primary);
}

.promiseImg{
    width: 70%;
}
