@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

body{
    margin: 0;
}

:root {
    --primary:#945030; 
}

*{
    font-family: 'Montserrat', sans-serif;
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgb(209, 209, 209);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--primary)
}

/* Navbar */
.nav{
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px black;
}

.menuBtn{
    display: none;
}

@media screen and (max-width: 930px) {
    .navMenu .navMenuLinks .mobileMenu{
        height: 100%;
        width: 75%;
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        background-color: white;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 30px;
    }
    .navMenu .navMenuLinks .mobileMenu a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 15px;
        display: block;
        transition: 0.3s;
    }
    .menuBtn{
        display: block;
    }

    .desktopNav{
        display: none;
    }
}

.nav .logo{
    width: 150px;
}

.nav a{
    text-decoration: none;
    padding: 10px;
    margin: 10px;
    color: var(--primary);
    font-weight: 500;
}

.nav .icons{
    display: flex;
    justify-content: space-between;   
}

.nav .icons svg{
    color: var(--primary);
    margin: 0px 7px;
    stroke-width: 1.3px;
}

/* Footer */
.footerLink{
    text-decoration: none;
    color: white;
    margin: 10px 0px;
    font-size: 13px;
}

.copyright{
    color: white;
    font-size: 13px;
}

/* Products */
.productImage{
    width: 300px;
    height: 300px;
}

.productLink{
    text-decoration: none;
    color: black;
    transition: 0.3s;
    font-size:13px; 
    font-weight: 500;
    max-width: 300px;
}

.productLink:hover{
    color: var(--primary)
}



/* Cart Sidebar */
.cartSidebar{
    position: fixed;
    min-height: 100vh;
    width: 100%;
    z-index: 9999;
    background-color: rgb(0, 0, 0, 0.7);
}

.cartSidebar .sidebarBody{
    width: 450px;
    background-color: white;
    min-height: 100vh;
    right: 0;
    position: fixed;   
}

.sidebarQuantity{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
}

.sidebarQuantity{
    font-weight: 500;
}

.sidebarQuantity .quantityBtns{
    border: 1px solid gray;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}

.sidebarQuantity input{
    width: 40px;
    text-align: center;
    border: none;
}

.sidebarQuantity button{
    border: none;
    font-size: 20px;
    background-color: transparent;
}


@media screen and (max-width: 450px) {
    .cartSidebar .sidebarBody{
        width: 100%;
    }
}

.checkOutBtn{
    padding: 12px 20px;
    border: none;
    font-weight: 600;
    color: white;
    font-size: 15px;
    width: 100%;
    text-transform: uppercase;
    background-color: var(--primary);
    cursor: pointer;
    margin: 10px 0px;
    transition: 0.3s;
    border: 1px solid var(--primary);
}

.checkOutBtn:hover{
    background-color: white;
    color: var(--primary);
}