.originalPrice{
    text-decoration: line-through;
    color: gray;
    margin-right: 10px;
    font-weight: 500;
}

.discountPrice{
    color: var(--primary);
    font-weight: 500;
}

.quantity {
    display: flex;
    justify-content: space-evenly;
    width: 120px;
    align-items: stretch;
    border: 1px solid ;
    border-radius: 25px;
    height: 40px;
    margin-right: 10px;
}

.quantity button{
    background-color: transparent;
    border: none;
    font-size: 27px;
    font-weight: 600;
}

.quantity input{
    width: 40px;
    border: none;
    outline: none;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
}

.quantity input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

.addCartBtn{
    padding: 12px 20px;
    border: none;
    border-radius: 25px;
    font-weight: 600;
    color: white;
    font-size: 15px;
    text-transform: uppercase;
    background-color: var(--primary);
    cursor: pointer;
    transition: 0.3s;
    border: 1px solid var(--primary);
}

.addCartBtn:hover{
    background-color: white;
    color: var(--primary);
}

.promiseImg{
    width: 70%;
}